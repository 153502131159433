.navbar_logo {
  height: 4rem;
  cursor: pointer;
}

.navbar,
.m_navbar {
  display: flex;
  justify-content: space-evenly;
  list-style: none;
  align-items: center;
  /* gap: 1rem; */
}

/* Small Screen Navbar  */
.m_navbar {
  display: none;
  justify-content: space-between;
}

.m_navbar li:nth-child(3) {
  margin-left: auto;
}

.collapseSec {
  display: none;
  height: 0;
  transition: all 0.2s ease-in-out;
  justify-content: center;
  overflow: hidden;
}

.collapseSec.active {
  height: 15rem;
  left: 0;
}

.collapseSec.active .co_nav_item {
  left: 0;
}

.co_nav {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.co_nav_item {
  position: inherit;
  left: -50rem;
  transition: all 0.2s ease-in-out;
}

/* Delay Effect */
.co_nav_item:nth-child(1) {
  transition-delay: 0.05s;
}
.co_nav_item:nth-child(2) {
  transition-delay: 0.15s;
}
.co_nav_item:nth-child(3) {
  transition-delay: 0.25s;
}
.co_nav_item:nth-child(4) {
  transition-delay: 0.35s;
}
.co_nav_item:nth-child(5) {
  transition-delay: 0.45s;
}

.icon_box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.search_box {
  display: flex;
  position: relative;
  background-color: #fff;
  border-radius: 10px;
  width: 100%;
}

.search_box .icon {
  position: absolute;
  top: 6px;
  left: 6px;
  color: #000;
}

.search {
  padding: 0.5rem 1rem;
  padding-left: 2.5rem;
  font-size: 1rem;
  width: 100%;
  border-radius: 10px;
  border: none;
}

.search:focus {
  outline: none;
}

.nav_link {
  cursor: pointer;
  transition: all 0.15s ease-in-out;
  text-decoration: none;
  color: #fff;
}

.icon {
  width: 2rem;
  color: #fff;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.icon:hover {
  opacity: 0.5;
}

@media only screen and (max-width: 1024px) {
  .navbar {
    display: none;
  }

  .m_navbar {
    display: flex;
  }

  .collapseSec {
    display: flex;
  }
}
.pd-left-5 {
  padding-left: 5%;
}

.dropdownOptions {
  font-family: jakarta-sans;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 28px;
  letter-spacing: 0;
  color: #181818;
}

.dropdownOptionsScreen {
  top: 133px;
  left: 1173px;
  width: 347px;
  height: 65px;
  /* UI Properties */
  /* background: white 0% 0% no-repeat padding-box; */
  /* opacity: 0; */
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  
}

:hover.dropdownOptionsScreen {
  top: 133px;
  left: 1176px;
  width: 341px;
  height: 65px;
  /* UI Properties */
  /* background: #FCFFF2 0% 0% no-repeat padding-box; */
  opacity: 1;
}

.dropdownIcon {
  top: 156px;
  left: 1208px;
  width: 8px;
  height: 8px;
  /* UI Properties */
  color: black;
  opacity: 1;
}

/* :hover.dropdownIcon {
  top: 167px;
  left: 1206px;
  width: 13px;
  height: 7px;
}*/
.dropdownNameNetwork {
  top: 153px;
  left: 1626px;
  height: 23px;
  /* UI Properties */
  text-align: left;
  /* font: normal normal 600 18px/23px Plus Jakarta Sans; */
  letter-spacing: 0px;
  color: #181818;
  opacity: 1;
}

.dropdownName {
  top: 153px;
  left: 1626px;
  width: 56px;
  height: 23px;
  /* UI Properties */
  text-align: left;
  /* font: normal normal 600 18px/23px Plus Jakarta Sans; */
  letter-spacing: 0px;
  color: #181818;
  opacity: 1;
}

:hover.dropdownName {
  top: 153px;
  left: 1626px;
  width: 56px;
  height: 23px;
  /* UI Properties */
  text-align: left;
  font-weight: bold;
  letter-spacing: 0px;
  color: #181818;
  opacity: 1;
}

.dropdownBox {
  width: 347px;
  /* background-color: #80808021; */
  /* height: 226px; */
  /* background: #ffffffef 0% 0% no-repeat padding-box; */
  box-shadow: 0px 15px 40px #0c0c0c0d;
  opacity: 1;
  /* right: 0%; */
  /* position: absolute; */
  /* left: 1173px; */
  /* position: absolute; */
  border-radius: 16px;
}
.currencydropdownBox {
  width: 230px;
  /* background-color: #80808021; */
  /* height: 226px; */
  /* background: #ffffffef 0% 0% no-repeat padding-box; */
  box-shadow: 0px 15px 40px #0c0c0c0d;
  opacity: 1;
  /* right: 0%; */
  /* position: absolute; */
  /* left: 1173px; */
  /* position: absolute; */
  border-radius: 16px;
}

.selectedchain {
  border: 0.699999px solid #6c0a8c;
  border-radius: 10px;
}
