* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container {
  display: flex;
  height: 100vh;
  overflow-y: hidden;
  
  /* flex-wrap: nowrap; */
}
main {
  /* padding: 10px; */
}

.title {
  font-size: 3rem;
  display: grid;
  place-items: center;
}

/* Sidebar */
.sidebar {
  /* background: #FFFFFF; */
  /* position: fixed; */
  height: 100vh;
  width: 230px; /* Default width */
  transition: width 0.5s ease; /* Smooth transition for width change */
}
.sidebar-closed {
  width: 90px; /* Reduced width when the sidebar is closed */
}

.top_section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 15px 10px; */
}
.logo {
  font-size: 18px;
  line-height: 0;
}
.bars {
  /* width: 30px; */
}
.hide {
  display: none;
}

.search {
  display: flex;
  align-items: center;
  margin: 10px 0;
  height: 30px;
  padding: 10px;
}
.search input {
  border: none;
  margin-left: 10px;
  border-radius: 5px;
  background: rgb(238, 238, 238);

  color: white;
}

.routes {
  /* margin-top: 15px; */
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.link {
 
  padding: 5px 10px;
  border-right: 4px solid transparent;
  text-decoration: none;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

.link:hover {
  /* border-right: 4px solid white;
  background: rgb(45, 51, 89);
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045); */
}
.link_text {
  white-space: nowrap;
  font-size: 15px;
}


.navitemactive {
  background: #3736AF;
  color: white;
  border-radius: 5px;
  padding: 8px 10px;
  font-size: 15px;
}
.navitemnotactive {
  color: #717579;
   font-size: 15px;
                          
}
.navitemnotactive:hover {
  color: #3736AF;
   font-size: 15px;
                          
}

.menu {
  display: flex;
  color: white;

  padding: 5px 10px;
  border-right: 4px solid transparent;
  transition: 0.2s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  justify-content: space-between;
}
.menu_item {
  display: flex;
  gap: 10px;
}
.menu_container {
  display: flex;
  flex-direction: column;
}
.menu_container .link {
  padding-left: 20px;
  border-bottom: #fff 0.5px solid;
}


.nav-menu {
  background: rgb(0, 7, 61);
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 50px;
  left: -100%;
  transition: 850ms;
}



.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-mobile-menu {
    background: rgb(0, 7, 61);
    width: 200px;
    height: 100vh;
    display: flex;
    position: fixed;
    top: 50px;
    
    transition: transform 0.3s linear;
}

.nav-mobile-menu.active {
     transition: transform 0.3s linear;
}